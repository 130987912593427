import * as React from 'react'
import styled from '@emotion/styled'

export const Wrapper = styled.div`
 
  padding-top: 4.8rem;
  padding-bottom: 3.6rem;
  
  .intro {
   display: grid;
  grid-gap: 3.6rem;
  grid-template-columns: 40% auto;
  }
`

const Hero: React.FC = ({ children }) => <Wrapper>{children}</Wrapper>

export default Hero

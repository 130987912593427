import styled from '@emotion/styled'
import * as React from 'react'
import { dimensions } from '../../styles/variables'
import { Tagline } from '../../utils/types'

const Wrapper = styled.div`
  text-align: center;
`
const IconWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 3.6rem;
  height: 3.6rem;

  img {
    width: 100%;
  }
`

const Description = styled.p`
  font-size: ${dimensions.fontSize.small}px;
`

const Title = styled.h5`
  font-size: ${dimensions.fontSize.regular}px;
`

interface TaglineProps {
  item: Tagline
}

type Props = TaglineProps

const TaglineItem: React.FC<Props> = ({ item }) => {
  return (
    <Wrapper>
      <IconWrapper>
        <img src={item.graphic.url} alt={item.graphic.alt} />
      </IconWrapper>
      <Title>{item.title.text}</Title>
      <Description>{item.description.text}</Description>
    </Wrapper>
  )
}

export default TaglineItem

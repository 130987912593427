import * as React from 'react'
import Page from 'components/Page'
import IndexLayout from 'layouts'
import { StyledButton } from 'components/Button'
import { graphql } from 'gatsby'
import { Box, Column, Section } from 'rbx'
import { ButtonGroup } from 'rbx/elements/button/button-group'
import { css } from '@emotion/core'
import { ChevronRight } from 'styled-icons/feather/ChevronRight'
import { Tool } from 'styled-icons/feather/Tool'
import { Cpu } from 'styled-icons/feather/Cpu'
import DashboardOrders from 'assets/images/media/screens/dashboard-orders-browser.png'
import OfficeToolkit from 'assets/images/media/office-toolkit.jpg'
import Hero from '../components/Hero'
import { StyledParagraph } from '../components/Base'
import { StyledHeading } from '../components/Heading'
import Statement from '../components/Statement'
import Intro from '../components/Intro/intro'
import { Channel, Tagline } from '../utils/types'
import TaglineItem from '../components/Taglines/Item'
import IntegrationItem from '../components/Integration/Item'
import StyledContainer from '../components/Container'
import StyledImg from '../components/Img'
import { colors, dimensions } from '../styles/variables'

const IndexPage = ({ data }) => {
  /**
   * Taglines
   */
  const {
    allPrismicTagline: { edges }
  } = data
  const taglineEdges = edges
  const taglines = [] as Tagline[]
  taglineEdges.forEach(({ node: { data } }) => taglines.push(data))

  /**
   * Channels
   */
  const { allPrismicChannel } = data
  const channelEdges = allPrismicChannel.edges
  const channels = [] as Channel[]
  channelEdges.forEach(({ node }: any) => channels.push(node))

  /**
   * Hero
   */
  const { prismicHomepage } = data
  const homeData = prismicHomepage.data

  return (
    <IndexLayout>
      <Page>
        <StyledContainer>
          {/* Introduction */}
          <Hero>
            <Column.Group>
              <Column size={5}>
                <h1>You sell.</h1>
                <h1>We'll handle the rest.</h1>
                <StyledParagraph padding="1.2rem 0 1.2rem">
                  OrderSenta is a centralized dashboard that allows online merchants to process and fulfil orders from multiple sales
                  channels, simultaneously.
                </StyledParagraph>
              </Column>

              <Column>
                <StyledImg src={homeData.hero_image.url} />
              </Column>
            </Column.Group>

            <StyledButton color="primary" as="a" href={`${process.env.API_URL}/auth/signup`}>
              Start free 14-day trial
            </StyledButton>
          </Hero>
        </StyledContainer>
        <Section
          css={css`
            margin-top: -10rem;
            padding-bottom: 0;
            background-image: linear-gradient(-6deg, #f8fafc, #f8fafc 74%, #fff 0, #fff);
            min-height: 420px;
          `}
        >
          <StyledContainer>
            <Column.Group
              gapSize={8}
              css={css`
                margin-top: 16rem;
              `}
            >
              <Column size="half">
                <Box
                  css={css`
                    padding: 0;
                    height: 100%;
                  `}
                >
                  <StyledImg
                    src={OfficeToolkit}
                    css={css`
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    `}
                  />
                </Box>
              </Column>
              <Column size="half">
                <Tool color={colors.brand.main} size={48} />
                <h6
                  css={css`
                    margin: 0;
                    color: ${colors.brand.main};
                    line-height: 1;
                    text-transform: uppercase;
                  `}
                >
                  The Complete Toolkit to Manage Your Orders
                </h6>
                <StyledParagraph
                  padding="1.2rem 0"
                  css={css`
                    font-weight: ${dimensions.fontWeight.semibold};
                  `}
                >
                  Every buyer wants to have the items they purchased fulfilled and delivered in time. OrderSenta makes this a reality for
                  both customers and sellers.
                </StyledParagraph>
                <p>
                  You do not need to set up fulfillment processes and install order fulfillment apps on all the stores you sell on. Set it
                  up on just one, and we will get all your orders from all stores there for you to process. Running an online business,
                  especially on many stores can be a real challenge. You need OrderSenta to make it a bliss.
                </p>
                <hr />
                <a
                  href={`${process.env.API_URL}/auth/signup`}
                  css={css`
                    display: flex;
                    align-items: center;
                    line-height: 1;
                  `}
                >
                  <b>Start managing your orders</b>
                  <ChevronRight size={16} />
                </a>
              </Column>
            </Column.Group>
            <Column.Group
              centered
              css={css`
                padding-top: 4.8rem;
              `}
            >
              {channels.map(channel => (
                <Column size={2} key={channel.id}>
                  <IntegrationItem item={channel} />
                </Column>
              ))}
            </Column.Group>
          </StyledContainer>
        </Section>

        <div
          css={css`
            min-height: 12rem;
            background-image: linear-gradient(-6deg, #fff, #fff 50%, #f8fafc 0, #f8fafc);
          `}
        />

        <StyledContainer>
          <Statement margin="0 auto 4.8rem auto">
            <Cpu size={56} color={colors.brand.main} />
            <h5>All your orders at a glance</h5>
            <StyledParagraph>
              Get to see the orders from all the stores you sell on, on your OrderSenta orders page, and manage them with ease.
            </StyledParagraph>

            <Box
              css={css`
                padding: 0;
                height: 24rem;
              `}
            >
              <StyledImg
                src={DashboardOrders}
                css={css`
                  width: 100%;
                  height: 100%;
                `}
              />
            </Box>

            <p>
              No need to switch from one store to another. From a centralized dashboard, fulfill your orders, and keep your customers
              happily coming back to you.
            </p>
          </Statement>
        </StyledContainer>

        <div
          css={css`
            padding: 0 0 4.8rem 0;
            background-color: #f8fafc;

            :before {
              display: block;
              width: 100%;
              height: 9.6rem;
              background-image: linear-gradient(-6deg, #f8fafc, #f8fafc 50%, #fff 0, #fff);
              content: '';
            }
          `}
        >
          <StyledContainer>
            <Statement
              css={css`
                margin-top: 0;
                padding-top: 0;
              `}
            >
              <StyledHeading margin="0">Control Your Business</StyledHeading>
              <p>Enjoy the benefits of a centralized and automated order fulfillment process by using OrderSenta.</p>
            </Statement>
            <Column.Group multiline>
              {taglines.map(tagline => (
                <Column key={`tagline-${tagline.title.text}`} tablet={{ size: 'half' }} desktop={{ size: 'one-quarter' }}>
                  <TaglineItem item={tagline} />
                </Column>
              ))}
            </Column.Group>
          </StyledContainer>
        </div>

        <Intro>
          <div
            css={css`
              padding-bottom: 4.8rem;
            `}
          >
            <StyledHeading>Sync orders with OrderSenta today</StyledHeading>
            <p>Sign up today for free. No credit card required. Account can be cancelled at any time.</p>
            <ButtonGroup
              css={css`
                justify-content: center;
              `}
            >
              <StyledButton color="primary" href={`${process.env.API_URL}/auth/signup`}>
                Start free 14-day trial
              </StyledButton>
              <StyledButton>Contact sales</StyledButton>
            </ButtonGroup>

            {/* <hr /> */}

            {/* <small> */}
            {/*  OrderSenta is a wholly-owned LLC Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur ducimus error et eum, */}
            {/*  ipsa iste iure labore magnam nam neque obcaecati officiis omnis perferendis perspiciatis repudiandae sunt voluptate voluptatem */}
            {/*  voluptatum. */}
            {/* </small> */}
          </div>
        </Intro>
      </Page>
    </IndexLayout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    prismicHomepage {
      id
      data {
        hero_image {
          alt
          copyright
          url
        }
      }
    }
    allPrismicChannel {
      edges {
        node {
          id
          data {
            banner {
              alt
              copyright
              url
            }
            title {
              html
              text
            }
          }
        }
      }
    }
    allPrismicTagline(sort: { fields: id, order: ASC }) {
      edges {
        node {
          id
          data {
            description {
              html
              text
            }
            graphic {
              alt
              copyright
              url
            }
            title {
              html
              text
            }
          }
        }
      }
    }
  }
`

import * as React from 'react'
import styled from '@emotion/styled'
import { Channel } from '../../utils/types'

interface IntegrationProps {
  item: Channel
}

type Props = IntegrationProps

const Wrapper = styled.div`
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 2.4rem;
    vertical-align: middle;
  }
`

const IntegrationItem: React.FC<Props> = ({ item }) => (
  <Wrapper>
    <img src={item.data.banner.url} alt={item.data.banner.url} />
  </Wrapper>
)

export default IntegrationItem
